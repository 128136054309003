import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            component: () => import(/* webpackChunkName: "front" */ './views/Front.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            component: () => import(/* webpackChunkName: "interaction" */ './views/Interaction.vue'),
        },
        {
            path: '/webauthz/start',
            name: 'webauthz-start',
            component: () => import(/* webpackChunkName: "webauthz-start" */ './views/webauthz/Start.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ './views/webauthz/Prompt.vue'),
        },
        {
            path: '/link-account-user',
            name: 'link-account-user',
            component: () => import(/* webpackChunkName: "link-account-user" */ './views/LinkAccountUser.vue'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
            path: '/login-redirect',
            name: 'login-redirect',
            component: () => import(/* webpackChunkName: "login-redirect" */ './views/LoginRedirect.vue'),
        },
        {
            path: '/me',
            name: 'me',
            component: () => import(/* webpackChunkName: "me" */ './views/Me.vue'),
        },
        {
            path: '/account',
            name: 'account',
            component: () => import(/* webpackChunkName: "account" */ './views/Account.vue'),
        },
        {
            path: '/account/delete',
            name: 'account-delete',
            component: () => import(/* webpackChunkName: "account-delete" */ './views/AccountDelete.vue'),
        },
        {
            path: '/enterprise/add-realm',
            name: 'enterprise-add-realm',
            component: () => import(/* webpackChunkName: "enterprise-add-realm" */ './views/enterprise/AddRealm.vue'),
        },
        {
            path: '/preferences',
            name: 'preferences',
            component: () => import(/* webpackChunkName: "preferences" */ './views/Preferences.vue'),
        },
        {
            path: '/enterprise/users',
            name: 'enterprise-users',
            component: () => import(/* webpackChunkName: "users" */ './views/EnterpriseUsers.vue'),
        },
        {
            path: '/enterprise/reports',
            name: 'enterprise-reports',
            component: () => import(/* webpackChunkName: "reports" */ './views/EnterpriseReports.vue'),
        },
        {
            path: '/subscribe/personal',
            name: 'subscribe-personal',
            component: () => import(/* webpackChunkName: "subscribe-personal" */ './views/SubscribePersonal.vue'),
        },
        {
            path: '/subscribe/enterprise',
            name: 'subscribe-enterprise',
            component: () => import(/* webpackChunkName: "subscribe-enterprise" */ './views/SubscribeEnterprise.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
        },
        {
            path: '/dashboard/free',
            name: 'dashboard-free',
            component: () => import(/* webpackChunkName: "dashboard-free" */ './views/DashboardFree.vue'),
        },
        {
            path: '/dashboard/personal',
            name: 'dashboard-personal',
            component: () => import(/* webpackChunkName: "dashboard-personal" */ './views/DashboardPersonal.vue'),
        }, {
            path: '/dashboard/enterprise',
            name: 'dashboard-enterprise',
            component: () => import(/* webpackChunkName: "dashboard-enterprise" */ './views/DashboardEnterprise.vue'),
        },
        {
            path: '/register/quick',
            name: 'register-quick',
            component: () => import(/* webpackChunkName: "register-start" */ './views/register/Quick.vue'),
        },
        // {
        //     path: '/register/enterprise',
        //     name: 'register-enterprise',
        //     component: () => import(/* webpackChunkName: "register-enterprise" */ './views/register/Enterprise.vue'),
        // },
        // TODO: this is outdated, it might have been used by the "redirect method" of registering realm users, which we don't do anymore. Confirm that it isn't in use and then delete.
        {
            path: '/register',
            name: 'register',
            component: () => import(/* webpackChunkName: "register" */ './views/Register.vue'),
        },
        {
            path: '/access-recovery',
            name: 'access-recovery',
            component: () => import(/* webpackChunkName: "access-recovery" */ './views/AccessRecovery.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
