/*
Copyright (C) 2019 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

class HttpException {
    constructor(message, response) {
        this.message = message;
        this.response = response;
    }

    toString() {
        return `${this.response.status} ${this.response.statusText}: ${this.message}`;
    }
}

class Service {
    constructor(options) {
        this.url = options.serviceEndpoint;
    }

    getContext() {
        return ajax.get(`${this.url}/context`, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got context response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'context response from server', response };
            });
    }

    getVersion() {
        return ajax.get(`${this.url}/version`, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got version response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'version response from server', response };
            });
    }
}

class Session {
    constructor(options) {
        this.url = options.serviceEndpoint;
        this.csrfToken = null;
    }

    get() {
        const headers = {
            ...this.getHeadersWithCsrfToken(),
            Accept: 'application/json',
        };
        return ajax.get(`${this.url}/session`, {
            headers,
        })
            .then((response) => {
                console.log('got session response: %o', response);
                if (response.headers['content-type'] === 'application/json' && response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    getHeadersWithCsrfToken() {
        if (this.csrfToken == null) {
            const storedCsrfToken = localStorage.getItem('csrfGuardToken');
            if (storedCsrfToken !== null) {
                this.csrfToken = storedCsrfToken;
            }
        }
        const headers = {};
        const csrfGuardToken = this.csrfToken;
        if (csrfGuardToken) {
            headers['CSRF-Guard'] = csrfGuardToken;
        }
        return headers;
    }

    createCsrfToken() {
        const headers = {
            Accept: 'application/json',
        };
        return ajax.post(`${this.url}/session/csrf-guard`, null, {
            headers,
        })
            .then(((response) => {
                console.log('got csrf-guard response: %o', response);
                if (response.data) {
                    if (typeof response.data.token === 'string') {
                        this.csrfToken = response.data.token;
                        localStorage.setItem('csrfGuardToken', response.data.token);
                    }
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            }));
    }

    requestTransferSession(options) {
        return ajax.post(`${this.url}/session/transfer`, JSON.stringify(options), {
            headers: {
                ...this.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got request transfer session response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    /*
    options: { lockOnExit: <true|false|null> }
    */
    edit(options) {
        const headers = {
            ...this.getHeadersWithCsrfToken(),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
        return ajax.post(`${this.url}/session/edit`, JSON.stringify(options), {
            headers,
        })
            .then(((response) => {
                console.log('got edit response: %o', response);
                if (response.data) {
                    return response.data;
                }
                return {};
            }));
    }

    logout() {
        const headers = {
            ...this.getHeadersWithCsrfToken(),
            Accept: 'application/json',
        };
        return ajax.post(`${this.url}/session/logout`, null, {
            headers,
        })
            .then(((response) => {
                console.log('got logout response: %o', response);
                if (response.data) {
                    return response.data;
                }
                return {};
            }))
            .catch(((response) => ({ error: response.message })));
    }

    delete() {
        const headers = {
            ...this.getHeadersWithCsrfToken(),
            Accept: 'application/json',
        };
        return ajax.delete(`${this.url}/session`, null, {
            headers,
        })
            .then(((response) => {
                console.log('got session delete response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {};
                }
                return { error: 'unexpected response from server', response };
            }))
            .catch(((response) => ({ error: response.message })));
    }

    removeLockedUser(userId) {
        const headers = {
            ...this.getHeadersWithCsrfToken(),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
        return ajax.post(`${this.url}/session/edit/locked/remove`, null, {
            headers,
            params: {
                userId,
            },
        })
            .then(((response) => {
                console.log('got removeLockedUser response: %o', response);
                if (response.data) {
                    return response.data;
                }
                return {};
            }));
    }
}

class Login {
    constructor(options, context) {
        this.url = options.serviceEndpoint;
        this.context = context;
    }
}

class Message {
    constructor(options, context) {
        this.url = options.serviceEndpoint;
        this.context = context;
    }

    get(id) {
        return ajax.get(`${this.url}/message`, {
            params: {
                id,
            },
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got message response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    create(messageRequest) {
        console.log('configured url: %s', this.url);
        return ajax.post(`${this.url}/message`, JSON.stringify(messageRequest), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got store message response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    verifyEmail(verificationRequest) {
        console.log('configured url: %s', this.url);
        return ajax.post(`${this.url}/message/verify/email`, JSON.stringify(verificationRequest), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got verify email response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }
}

class Account {
    constructor(options, context) {
        this.url = options.serviceEndpoint;
        this.context = context;
    }

    get() {
        return ajax.get(`${this.url}/account`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got account settings response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    getUserList(accountId) {
        return ajax.get(`${this.url}/account/user`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
            params: {
                accountId,
            },
        })
            .then((response) => {
                console.log('got user list response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    edit(settings) {
        // settings: { sessionIdleExpiresMillis, sessionCookieExpiresMillis }
        // NOTE: you only need to specify the attributes that should be changed
        return ajax.post(`${this.url}/account/edit`, JSON.stringify(settings), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got store account settings response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    async editAccountImage(accountId, formData) {
        // settings: { sessionIdleExpiresMillis, sessionCookieExpiresMillis }
        // NOTE: you only need to specify the attributes that should be changed
        const response = await ajax.post(`${this.url}/account/edit/image`, formData, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
            params: {
                accountId,
            },
        });
        if (response) {
            console.log('got store account editAccountImage response: %o', response);
            if (response.status === 200 && response.data) {
                return response.data;
            }
            if (response.status === 202 || response.status === 204) {
                return { isEdited: true }; // successful response without data
            }
        }
        return { isEdited: false };
    }

    async deleteAccountImage(accountId) {
        // settings: { sessionIdleExpiresMillis, sessionCookieExpiresMillis }
        // NOTE: you only need to specify the attributes that should be changed
        const response = await ajax.delete(`${this.url}/account/edit/image`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
            params: {
                accountId,
            },
        });
        if (response) {
            console.log('deleteAccountImage response: %o', response);
            if (response.status === 200 && response.data) {
                return response.data;
            }
            if (response.status === 202 || response.status === 204) {
                return { isEdited: true }; // successful response without data
            }
        }
        return { isEdited: false };
    }

    async editRealmImage(id, formData) {
        // settings: { sessionIdleExpiresMillis, sessionCookieExpiresMillis }
        // NOTE: you only need to specify the attributes that should be changed
        const response = await ajax.post(`${this.url}/account/realm/edit/image`, formData, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
            params: {
                id,
            },
        });
        if (response) {
            console.log('got store account editImage response: %o', response);
            if (response.status === 200 && response.data) {
                return response.data;
            }
            if (response.status === 202 || response.status === 204) {
                return { isEdited: true }; // successful response without data
            }
        }
        return { isEdited: false };
    }

    async create(settings) {
        // settings: { name, email, type }
        // NOTE: you only need to specify the attributes that should be changed
        const response = await ajax.post(`${this.url}/account/create`, JSON.stringify(settings), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        if (response.status === 200 || response.status === 201 || response.status === 202 || response.status === 204) {
            return response.data ? response.data : {};
        }
        throw new HttpException('unexpected response', response);
    }

    invite(userInfo) {
        // userInfo: { accountId, name, email }
        return ajax.post(`${this.url}/account/user/invite`, JSON.stringify(userInfo), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got invite user response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    linkAccountUser(/* TODO: { invitationId } */userInfo) {
        return ajax.post(`${this.url}/account/user/link`, JSON.stringify(userInfo), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got linkAccountUser response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    editAccountUser(userInfo) {
        return ajax.post(`${this.url}/account/user/edit`, JSON.stringify(userInfo), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got edit user response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    unlinkAccountUser({ accountId, userId }) {
        return ajax.post(`${this.url}/account/user/unlink`, JSON.stringify({ accountId, userId }), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got unlinkAccountUser response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    delete({ accountId }) {
        console.log('sending delete request with accountId: %s', accountId);
        return ajax.delete(`${this.url}/account`, {
            params: {
                id: accountId,
            },
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got delete account response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    createAuthorizationToken(token) {
        return ajax.post(`${this.url}/account/authorization-token/create`, JSON.stringify(token), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got store account authorization token response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    editAuthorizationToken(token) {
        return ajax.post(`${this.url}/account/authorization-token/edit`, JSON.stringify(token), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got store account authorization token response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    deleteAuthorizationToken(tokenId) {
        return ajax.delete(`${this.url}/account/authorization-token`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            params: {
                id: tokenId,
            },
        })
            .then((response) => {
                console.log('got store account authorization token response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    getAuthorizationTokenList({ accountId }) {
        return ajax.get(`${this.url}/account/authorization-token`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
            params: {
                accountId,
            },
        })
            .then((response) => {
                console.log('got store account authorization token response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    createRealm(realmInfo) {
        return ajax.post(`${this.url}/account/realm/create`, JSON.stringify(realmInfo), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got create realm response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    editRealm(realmInfo) {
        return ajax.post(`${this.url}/account/realm/edit`, JSON.stringify(realmInfo), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got edit realm response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    deleteRealm(id) {
        return ajax.delete(`${this.url}/account/realm`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            params: {
                id,
            },
        })
            .then((response) => {
                console.log('got store account authorization token response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }

    getRealmList({ accountId }) {
        return ajax.get(`${this.url}/account/realm`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
            params: {
                accountId,
            },
        })
            .then((response) => {
                console.log('got realm list response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }
}

class Realm {
    constructor(options, context) {
        this.url = options.serviceEndpoint;
        this.context = context;
    }

    getById(id) {
        return ajax.get(`${this.url}/realm`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
            params: {
                id,
            },
        })
            .then((response) => {
                console.log('got realm info response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    getByAccountIdRealmURI(accountId, realmURI) {
        return ajax.get(`${this.url}/realm`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
            params: {
                accountId,
                uri: realmURI,
            },
        })
            .then((response) => {
                console.log('got realm info response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }
}

class User {
    constructor(options, context) {
        this.url = options.serviceEndpoint;
        this.context = context;
    }

    get() {
        return ajax.get(`${this.url}/user`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got user settings response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'user response from server', response };
            });
    }

    getClientList() {
        console.log('getClientList');
        return ajax.get(`${this.url}/user/client`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got getClientList response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    getSessionList() {
        console.log('getSessionList');
        return ajax.get(`${this.url}/user/session`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got getSessionList response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    editUserSession({ id, isTrusted }) {
        console.log('editUserSession');
        return ajax.get(`${this.url}/user/session/edit`, JSON.stringify({ id, isTrusted }), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got editUserSession response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    edit(settings) {
        // settings: { sessionIdleExpiresMillis, sessionCookieExpiresMillis, name }
        // NOTE: you only need to specify the attributes that should be changed
        return ajax.post(`${this.url}/user/edit`, JSON.stringify(settings), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got store user settings response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            });
    }
}

class Claim {
    constructor(options, context) {
        this.url = options.serviceEndpoint;
        this.context = context;
    }

    create(settings) {
        // settings: { accountId, type, email }
        return ajax.post(`${this.url}/claim`, JSON.stringify(settings), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got create claim response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            });
    }

    confirm(settings) {
        // settings: { id, code }
        return ajax.post(`${this.url}/claim/confirm`, JSON.stringify(settings), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('got confirm claim response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            });
    }
}

class Stripe {
    constructor(options, context) {
        this.stripe = options.stripe;
        this.context = context;
    }

    createSubscription(checkoutInfo) {
        return ajax.post(`${this.stripe}/session/create`, JSON.stringify(checkoutInfo), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                console.log('got stripe session response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }
}

class Interaction {
    constructor(options, context) {
        this.url = options.serviceEndpoint;
        this.context = context;
    }

    create(request) {
        console.log('loginshield interaction create: request: %o', request);
        return ajax.post(`${this.url}/interaction`, JSON.stringify(request), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('create interaction response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    get(id) {
        return ajax.get(`${this.url}/interaction`, {
            params: {
                id,
            },
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('get interaction response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    edit(id, message) {
        return ajax.post(`${this.url}/interaction/edit`, JSON.stringify(message), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            params: {
                id,
            },
        })
            .then((response) => {
                console.log('edit interaction response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    getTokenStatus(tokenId) {
        console.log('getTokenStatus');
        return ajax.get(`${this.url}/interaction/token/status`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
            params: {
                id: tokenId,
            },
        })
            .then((response) => {
                console.log('got getTokenStatus response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    resumeWithToken(token) {
        console.log('resumeWithToken');
        return ajax.get(`${this.url}/interaction/resume`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
            params: {
                token,
            },
        })
            .then((response) => {
                console.log('got resumeWithToken response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }
}

class UserAuthzGeolocation {
    constructor(options, context) {
        this.url = options.serviceEndpoint;
        this.context = context;
    }

    list() {
        return ajax.get(`${this.url}/userAuthzGeolocation/list`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('loginshield.js userAuthzGeolocation getList response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    create(request) {
        console.log('loginshield.js userAuthzGeolocation create request: %o', request);
        return ajax.post(`${this.url}/userAuthzGeolocation/create`, JSON.stringify(request), {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                console.log('loginshield.js userAuthzGeolocation create response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    delete(id) {
        return ajax.delete(`${this.url}/userAuthzGeolocation`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            params: {
                id,
            },
        })
            .then((response) => {
                console.log('loginshield.js userAuthzGeolocation delete response: %o', response);
                if (response.data) {
                    return response.data;
                }

                return { error: 'unexpected response from server', response };
            });
    }

    deleteAuthorizationToken(tokenId) {
        return ajax.delete(`${this.url}/account/authorization-token`, {
            headers: {
                ...this.context.session.getHeadersWithCsrfToken(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            params: {
                id: tokenId,
            },
        })
            .then((response) => {
                console.log('got store account authorization token response: %o', response);
                if (response.data) {
                    return response.data;
                }
                if (response.status === 202 || response.status === 204) {
                    return {}; // successful response without data
                }
                return { error: 'unexpected response from server', response };
            })
            .catch((response) => {
                if (response.data) {
                    return response.data;
                }
                return { error: 'unexpected response from server', response };
            });
    }
}

class Client {
    constructor(options) {
        this.options = options; // { url }
        this.service = new Service(options);
        this.session = new Session(options, this);
        this.login = new Login(options, this);
        this.message = new Message(options, this);
        this.account = new Account(options, this);
        this.realm = new Realm(options, this);
        this.user = new User(options, this);
        this.claim = new Claim(options, this);
        this.stripe = new Stripe(options, this);
        this.interaction = new Interaction(options, this);
        this.userAuthzGeolocation = new UserAuthzGeolocation(options, this);
    }
}

export default Client;
