// import { TrackClient } from '@libertyio/track-client-sdk-js';
// import { OptinClient } from '@libertyio/optin-client-sdk-js';
import Vue from 'vue';
import Vuex from 'vuex';

import loginshield from '@/client';
import { ACCOUNT_TYPE_ENTERPRISE } from '@/constants';

Vue.use(Vuex);

console.log('store.js loaded');

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and account info from server
        serviceContext: {}, // stripeTokenPublicKey
        serviceVersion: {}, // version
        session: { isAuthenticated: false }, // isAuthenticated, lockOnExit, notAfter, isCsrfGuardEnabled, rememberMeList, isTrusted (only valid when authenticated)
        user: {}, // name, agreeToTermsDate, deviceInfoMap, sessionIdleExpiresMillis, sessionLockExpiresMillis
        accountId: null, // selected account id (see accountMap for account info)
        accountMap: {}, // id => account (actually LinkAccountUserInfo): permit, accountId, name, type, icon, isGravatarEnabled, verified: {email:[],phone:[]}  ; these are accounts associated to the current user
        interactionMap: {}, // id => interaction ( type: string, data: object )
        claimRequestMap: {}, // id => { accountId, type, email }
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        enterpriseUserList: [], // these are users associated to the current enterprise account
        isTutorEnabled: false,
        loadingMap: { init: true },
        oidcInteraction: {}, // only contains data when testing specific responses from the server
    },
    getters: {
        nonEnterpriseAccountList(state) {
            const accounts = Object.values(state.accountMap).filter((item) => item.type !== ACCOUNT_TYPE_ENTERPRISE);
            accounts.sort((a, b) => (`${a.name}`).localeCompare(b.name));
            return accounts;
        },
        enterpriseAccountList(state) {
            const accounts = Object.values(state.accountMap).filter((item) => item.type === ACCOUNT_TYPE_ENTERPRISE);
            accounts.sort((a, b) => (`${a.name}`).localeCompare(b.name));
            return accounts;
        },
        combinedAccountList(state, getters) {
            return [...getters.nonEnterpriseAccountList, ...getters.enterpriseAccountList];
        },
        account(state) {
            return state.accountMap[state.accountId] || { id: state.accountId, type: 'Unknown' };
        },
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
    },
    mutations: {
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        setServiceContext(state, contextInfo) {
            state.serviceContext = contextInfo;
            console.log(`store.js: setServiceContext ${JSON.stringify(contextInfo)}`);
        },
        setServiceVersion(state, versionInfo) {
            state.serviceVersion = versionInfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        setUser(state, user) {
            state.user = user;
        },
        setAccountId(state, accountId) {
            state.accountId = accountId;
            state.enterpriseUserList = []; // must be reloaded for current account, if applicable
        },
        setAccountMap(state, accountMap) {
            state.accountMap = accountMap;
        },
        setInteraction(state, interaction) {
            state.interactionMap = { ...state.interactionMap, ...interaction };
        },
        setEnterpriseUserList(state, enterpriseUserList) {
            state.enterpriseUserList = enterpriseUserList;
        },
        setClaimRequestMap(state, claimRequestMap) {
            state.claimRequestMap = claimRequestMap;
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        setTutorState(state, value) {
            state.isTutorEnabled = value;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        oidcInteraction(state, value) {
            state.oidcInteraction = { ...state.oidcInteraction, ...value };
        },
    },
    actions: {
        /*
        async lock({ commit, state }) {
            await loginshield.session.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { ...state.session, isAuthenticated: false });
            commit('setAccountId', null);
            commit('setTutorState', false);
        },
        */
        async logout({ commit, state }) {
            commit('loading', { logout: true });
            await loginshield.session.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { ...state.session, isAuthenticated: false });
            commit('setAccountId', null);
            commit('setTutorState', false);
            commit('loading', { logout: false });
        },
        async enableCsrfGuard({ commit, state }) {
            commit('loading', { enableCsrfGuard: true });
            const csrfTokenResponse = await loginshield.session.createCsrfToken();
            if (csrfTokenResponse.token) {
                const csrfGuardToken = csrfTokenResponse.token;
                localStorage.setItem('csrfGuardToken', csrfGuardToken);
                commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
            }
            commit('loading', { enableCsrfGuard: false });
        },
        async removeLockedUser({ commit, state }, userId) {
            commit('loading', { removeLockedUser: true });
            try {
                const newSessionInfo = await loginshield.session.removeLockedUser(userId);
                commit('setSession', { ...state.session, ...newSessionInfo });
                commit('loading', { removeLockedUser: false });
                return true;
            } catch (err) {
                console.log('removeLockedUser: failed: %o', err);
                commit('loading', { removeLockedUser: false });
                return false;
            }
        },
        async init({ commit, dispatch, state }, { force = false } = {}) {
            if (state.isReady && !force) {
                console.log('vuex store: init already done');
                return;
            }
            console.log('vuex store: init');
            commit('loading', { init: true });
            try {
                await Promise.all([
                    dispatch('loadServiceInfo'),
                    dispatch('loadSession'),
                ]);
                if (state.session.isCsrfGuardEnabled && state.session.csrfGuardToken) {
                    // csrf guard enabled, store the token for use by loginshiedl client
                    localStorage.setItem('csrfGuardToken', state.session.csrfGuardToken);
                } else {
                    // enable csrf guard
                    await dispatch('enableCsrfGuard');
                }
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser'),
                        dispatch('loadAccount'),
                    ]);
                }
                commit('ready');
            } catch (err) {
                console.error('vuex store: init failed');
            }
            commit('loading', { init: false });
        },
        // default progressIndicator for refresh is false so users aren't surprised
        // by a sudden progress bar appearance while they're using the site; but
        // it's configurable because some views might want to do an explicit refresh
        // and show the progress bar
        async refresh({ dispatch, state }, { progressIndicator = false } = {}) {
            console.log('vuex store: refresh');
            try {
                await dispatch('loadSession', { progressIndicator });
                if (state.session.isAuthenticated) {
                    // load data concurrently
                    await Promise.all([
                        dispatch('loadUser', { progressIndicator }),
                        dispatch('loadAccount', { progressIndicator }),
                    ]);
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadServiceInfo({ commit }) {
            commit('loading', { loadServiceInfo: true });
            try {
                const [versionInfo, contextInfo] = await Promise.all([
                    loginshield.service.getVersion(),
                    loginshield.service.getContext(),
                ]);
                commit('setServiceVersion', versionInfo);
                commit('setServiceContext', contextInfo);
            } catch (err) {
                console.error('vuex store: failed to load service info');
            }
            commit('loading', { loadServiceInfo: false });
        },
        async loadSession({ commit, dispatch }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadSession: true });
            }
            try {
                const sessionInfo = await loginshield.session.get();
                commit('setSession', sessionInfo);
                const { isAuthenticated, notAfter } = sessionInfo;
                if (isAuthenticated && notAfter) {
                    const delay = notAfter - Date.now();
                    console.log(`vuex store: scheduling session reload for ${delay} ms`);
                    setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, delay);
                }
            } catch (err) {
                commit('setSession', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadSession: false });
        },
        async loadUser({ commit }, { progressIndicator = true } = {}) {
            if (progressIndicator) {
                commit('loading', { loadUser: true });
            }
            try {
                const userInfo = await loginshield.user.get();
                commit('setUser', userInfo);
            } catch (err) {
                commit('setUser', { fault: { type: 'read-failed' } });
            }
            commit('loading', { loadUser: false });
        },
        async loadEnterpriseUserList({ commit, state }) {
            commit('loading', { loadEnterpriseUserList: true });
            try {
                const userListResponse = await loginshield.account.getUserList(state.accountId);
                commit('setEnterpriseUserList', userListResponse.data);
            } catch (err) {
                commit('setEnterpriseUserList', []);
            }
            commit('loading', { loadEnterpriseUserList: false });
        },
        async loadAccount({ commit, getters, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadAccount: true });
                }
                const accountResponse = await loginshield.account.get();
                // account response includes 'data' with list of accounts; each one with permit, accountId, account {name, type, icon, isGravatarEnabled}
                if (accountResponse.data && accountResponse.data.length > 0) {
                    const accountMap = {};
                    accountResponse.data.forEach((item) => {
                        const account = {
                            permit: item.permit || [],
                            id: item.accountId,
                            name: item.account.name,
                            type: item.account.type,
                            icon: item.account.icon,
                            email: item.email, // TODO: this is just so gravatar will work on free account with account.isGravatarEnabled && account.email, but if a user could have more than one email address then whatever uses the Avatar component should have a way to select an email address to pass to it
                            isGravatarEnabled: item.account.isGravatarEnabled,
                            verified: item.verified,
                        };
                        accountMap[item.accountId] = account;
                    });
                    commit('setAccountMap', accountMap);

                    // if no account selected, or if selected account does not match any account in the accountMap, automatically select the first account in the list
                    if (state.accountId === null || !accountMap[state.accountId]) {
                        if (getters.combinedAccountList.length > 0) {
                            const accountId = getters.combinedAccountList[0].id;
                            console.log('vuex store: setting account id to first available account id: %o', accountId);
                            commit('setAccountId', accountId);
                        }
                    }
                } else {
                    commit('setAccountMap', {});
                    commit('setAccountId', null);
                    console.log('vuex store: setting account id to null');
                }
            } catch (err) {
                commit('setAccountMap', {});
                commit('setAccountId', null);
                console.log('store.loadAccount: failed: %o', err);
            } finally {
                commit('loading', { loadAccount: false });
            }
        },
        async editSession({ commit }, sessionInfo) {
            commit('loading', { editSession: true });
            try {
                const newSessionInfo = await loginshield.session.edit(sessionInfo);
                commit('setSession', newSessionInfo);
                commit('loading', { editSession: false });
                return true;
            } catch (err) {
                console.log('editSession error: %o', err);
                commit('loading', { editSession: false });
                return false;
            }
        },
        async editUser({ commit, state }, userInfo) {
            commit('loading', { editUser: true });
            try {
                await loginshield.user.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, ...userInfo };
                commit('setUser', newUserInfo);
                commit('loading', { editUser: false });
                return true;
            } catch (err) {
                console.log('editUser error: %o', err);
                commit('loading', { editUser: false });
                return false;
            }
        },
        async editAccount({ commit, state }, accountInfo) {
            commit('loading', { editAccount: true });
            try {
                // change account type if necessary
                await loginshield.account.edit(accountInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                console.log('setAccountMap %o: ', accountInfo);
                const existingAccountInfo = state.accountMap[accountInfo.id];
                console.log('existingAccountInfo %o: ', existingAccountInfo);
                const newAccountInfo = { ...existingAccountInfo, ...accountInfo };
                console.log('newAccountInfo %o: ', newAccountInfo);
                const newAccountMap = { ...state.accountMap, [accountInfo.id]: newAccountInfo };
                console.log('setAccountMap %o: ', newAccountMap);
                commit('setAccountMap', newAccountMap);
                commit('loading', { editAccount: false });
                return true;
            } catch (err) {
                console.log('editUser error: %o', err);
                commit('loading', { editAccount: false });
                return false;
            }
        },
        async editAccountImage({ commit, dispatch, state }, fileInput) {
            commit('loading', { editAccountImage: true });
            try {
                // https://developer.mozilla.org/en-US/docs/Web/API/FormData
                const formData = new FormData();
                formData.append('file', fileInput);
                const result = await loginshield.account.editAccountImage(state.accountId, formData);
                await dispatch('loadAccount'); // TODO: reload just the affected account
                commit('loading', { editAccountImage: false });
                return result && result.isEdited;
            } catch (err) {
                console.log('editAccountImage error: %o', err);
                return false;
            } finally {
                commit('loading', { editAccountImage: false });
            }
        },
        async deleteAccountImage({ commit, dispatch, state }) {
            commit('loading', { deleteAccountImage: true });
            try {
                // https://developer.mozilla.org/en-US/docs/Web/API/FormData
                const result = await loginshield.account.deleteAccountImage(state.accountId);
                await dispatch('loadAccount'); // TODO: reload just the affected account
                commit('loading', { deleteAccountImage: false });
                return result && result.isEdited;
            } catch (err) {
                console.log('deleteAccountImage error: %o', err);
                return false;
            } finally {
                commit('loading', { deleteAccountImage: false });
            }
        },
        async editRealmImage({ commit, dispatch }, { id, fileInput }) {
            commit('loading', { editRealmImage: true });
            try {
                // https://developer.mozilla.org/en-US/docs/Web/API/FormData
                const formData = new FormData();
                formData.append('file', fileInput);
                const result = await loginshield.account.editRealmImage(id, formData);
                await dispatch('loadAccount'); // TODO: reload just the affected account
                commit('loading', { editRealmImage: false });
                return result && result.isEdited;
            } catch (err) {
                console.log('editRealmImage error: %o', err);
                commit('loading', { editRealmImage: false });
                return false;
            }
        },
        async switchAccount({ commit }, accountId) {
            // commit('loading', { switchAccount: true });
            commit('setAccountId', accountId);
            // commit('loading', { switchAccount: false });
        },
        // NOTE: createAccount isn't used right now; it is created automatically during subscription
        async createAccount({ commit, dispatch }, accountInfo) {
            commit('loading', { createAccount: true });
            const response = await loginshield.account.create(accountInfo);
            await dispatch('loadAccount');
            commit('loading', { createAccount: false });
            return response;
        },
        async deleteAccount({
            commit, dispatch, getters, state,
        }, accountInfo) {
            commit('loading', { deleteAccount: true });
            const status = {};
            try {
                await loginshield.account.delete(accountInfo);
                status.deleted = true;
                if (state.accountId === accountInfo.accountId) {
                    status.switchAccount = true;
                }
                await dispatch('loadAccount');
                if (getters.combinedAccountList.length === 0) {
                    await dispatch('logout');
                    status.logout = true;
                } else if (status.switchAccount) {
                    await dispatch('switchAccount', getters.combinedAccountList[0].id);
                }
            } catch (err) {
                status.deleted = false;
                status.error = err;
            }
            commit('loading', { deleteAccount: false });
            return status;
        },
        async createInteraction({ commit }, interaction) {
            try {
                commit('loading', { createInteraction: true });
                console.log('store: createInteraction %o', interaction);
                const response = await loginshield.interaction.create(interaction);
                if (response.id) {
                    commit('setInteraction', { [response.id]: response });
                }
                return response;
            } catch (err) {
                console.error('store: createInteraction failed', err);
                return { isCreated: false };
            } finally {
                commit('loading', { createInteraction: false });
            }
        },
        async editInteraction({ commit }, { interactionId, message }) {
            console.log('store: editInteraction %s', interactionId);
            try {
                commit('loading', { editInteraction: true });
                const response = await loginshield.interaction.edit(interactionId, message);
                if (response.id) {
                    commit('setInteraction', { [response.id]: response });
                }
                return response;
            } catch (err) {
                console.error('store: editInteraction failed', err);
                return { isEdited: false };
            } finally {
                commit('loading', { editInteraction: false });
            }
        },
        async loadInteraction({ commit }, interactionId) {
            try {
                commit('loading', { loadInteraction: true });
                const response = await loginshield.interaction.get(interactionId);
                commit('setInteraction', { [interactionId]: response });
                return response;
            } catch (err) {
                return null;
            } finally {
                commit('loading', { loadInteraction: false });
            }
        },
        async loadSessionList({ commit }) {
            commit('loading', { loadSessionList: true });
            try {
                const browserListResponse = await loginshield.user.getSessionList();
                // commit('sessionList', sessionListResponse.data);
                commit('loading', { loadSessionList: false });
                return browserListResponse;
            } catch (err) {
                // commit('sessionList', []);
                commit('loading', { loadSessionList: false });
                return null;
            }
        },
        async editUserSession({ commit }, editRequest) {
            commit('loading', { editUserSession: true });
            try {
                const editSessionResponse = await loginshield.user.editUserSession(editRequest);
                // commit('sessionList', sessionListResponse.data);
                commit('loading', { editSessionResponse: false });
                return editSessionResponse;
            } catch (err) {
                commit('loading', { editUserSession: false });
                return null;
            }
        },
        async inviteUser({ commit, dispatch }, userInfo) {
            commit('loading', { inviteUser: true });
            const response = await loginshield.account.invite(userInfo);
            await dispatch('loadEnterpriseUserList');
            commit('loading', { inviteUser: false });
            return response;
        },
        async acceptInvitation({ commit, dispatch }, { interactionId }) {
            commit('loading', { acceptInvitation: true });
            const response = await dispatch('editInteraction', { interactionId, message: { action: 'accept' } });
            await dispatch('loadAccount');
            commit('loading', { acceptInvitation: false });
            return response;
        },
        async editAccountUser({ commit, dispatch }, userInfo) {
            commit('loading', { editAccountUser: true });
            try {
                const response = await loginshield.account.editAccountUser(userInfo);
                await dispatch('loadEnterpriseUserList');
                commit('loading', { editAccountUser: false });
                return response;
            } catch (err) {
                console.log('editAccountUser error: %o', err);
                commit('loading', { editAccountUser: false });
                return null;
            }
        },
        async unlinkAccountUser({ commit, dispatch }, userInfo) {
            commit('loading', { unlinkAccountUser: true });
            try {
                const response = await loginshield.account.unlinkAccountUser(userInfo);
                await dispatch('loadEnterpriseUserList');
                commit('loading', { unlinkAccountUser: false });
                return response;
            } catch (err) {
                console.log('editAccountUser error: %o', err);
                commit('loading', { unlinkAccountUser: false });
                return null;
            }
        },
        async createClaim({ commit, state }, claimRequest) {
            commit('loading', { createClaim: true });
            try {
                const claimResponse = await loginshield.claim.create(claimRequest);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newClaimRequestMap = { ...state.claimRequestMap, [claimResponse.id]: claimRequest };
                commit('setClaimRequestMap', newClaimRequestMap);
            } catch (err) {
                console.log('createClaim error: %o', err);
            }
            commit('loading', { createClaim: false });
        },
        async confirmClaim({ commit, state }, confirmRequest) {
            commit('loading', { confirmClaim: true });
            try {
                const confirmResponse = await loginshield.claim.confirm(confirmRequest);
                if (confirmResponse.error || confirmResponse.fault) {
                    console.log('confirmClaim error: %o', confirmResponse);
                } else {
                    const newClaimRequestMap = { ...state.claimRequestMap };
                    delete newClaimRequestMap[confirmRequest.id];
                    commit('setClaimRequestMap', newClaimRequestMap);
                }
            } catch (err) {
                console.log('confirmClaim error: %o', err);
            }
            commit('loading', { confirmClaim: false });
        },
        async setTutorState({ commit }, value) {
            commit('loading', { setTutorState: true });
            commit('setTutorState', value);
            commit('loading', { setTutorState: false });
        },
        // OIDC
        async getInteractionDetails({ commit, state }, interactionId) {
            commit('loading', { getInteractionDetails: true });
            // in development we might pre-fill the interaction result with sample data
            // in production we always get the current data; that's why in this method we
            // don't actually commit anything to the state
            if (state.oidcInteraction[interactionId]) {
                commit('loading', { getInteractionDetails: false });
                return state.oidcInteraction[interactionId];
            }
            const oidcResponse = await loginshield.oidc.getInteractionDetails(interactionId);
            commit('loading', { getInteractionDetails: false });
            return oidcResponse;
        },
    },
});
